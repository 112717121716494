import clsx from '@vangst/lib/clsx'
import { IconType } from 'react-icons'
import { GoContactSupport } from '../../domains/viewer/navigation/GoDoThings'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly icon?: IconType
  readonly stack?: string
  readonly title: string
}

/**
 * Display alert text along with meta information in a box like thing.
 *
 * @see [MDN Alert Role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role)
 *
 * @example
 * <AlertError aria-live="assertive" icon={MdError} title="Server Error.">
 *   The default AlertError
 * </AlertError>
 * <AlertError icon={MdErrorOutline} stack="HttpError | 540" title="Oh no!">
 *   An AlertError which prints out the stack trace.
 * </AlertError>
 */
function AlertError(props: PropsType) {
  const { children, className, icon, title, stack, ...rest } = props
  const Icon = icon

  return (
    <section
      className={clsx(
        'contain-xyp mood-white flex flex-col items-center justify-center gap-4 border',
        className,
      )}
      {...rest}
      // Passing this below to the <h1> for a less noisy alert...
      aria-live={undefined}
    >
      {Icon && <Icon className="text-orange" size="3rem" />}
      <h1 className="heading-sm">
        <span aria-live={props['aria-live']} role="alert">
          {title}
        </span>
      </h1>
      {children}
      {stack && process.env.NEXT_PUBLIC_OOGST_PRODUCTION == null && (
        <div className="max-h-80 overflow-y-auto py-4 pr-4 text-grey">
          <pre className="whitespace-pre-wrap text-sm">{stack}</pre>
        </div>
      )}
      <GoContactSupport />
    </section>
  )
}

export default AlertError
