import Routes from '@vangst/services/routes'
import { NextPageContext } from 'next'
import { ErrorProps } from 'next/error'
import { MdErrorOutline } from 'react-icons/md'
import AlertError from '../components/feedback/AlertError'
import ClickyLink from '../components/navigation/ClickyLink'
import ListPseudos from '../components/views/ListPseudos'
import { httpErrors } from '../domains/application/feedback/AlertHttpError'
import Meta from '../domains/application/Meta'

export default function CustomError({ statusCode }: ErrorProps) {
  type keyType = keyof typeof httpErrors

  const key =
    (Object.keys(httpErrors).find(
      (k) => httpErrors[k as keyType]?.code === statusCode,
    ) as keyType | undefined) || 'unknown'

  const code = httpErrors[key]?.code
  const title = httpErrors[key]?.title
  const message = httpErrors[key]?.pageMessage

  return (
    <>
      <Meta title={`${code}: ${title}`} key="meta" />
      <div className="contain-xyc">
        <AlertError
          aria-live="assertive"
          icon={MdErrorOutline}
          stack={`HttpError | ${code}`}
          title={title}
        >
          {message}
        </AlertError>
      </div>

      <div className="contain-xyc flow-y-sm items-center">
        <ListPseudos as="•" at="after" className="flex flex-wrap gap-2">
          <li>
            <ClickyLink className="link-orange underline" href="/">
              Go Home
            </ClickyLink>
          </li>
          <li>
            <ClickyLink className="link-orange underline" href={Routes.JOBS}>
              Search Jobs
            </ClickyLink>
          </li>
          <li>
            <ClickyLink
              className="link-orange underline"
              href={Routes.TRAININGS}
            >
              Search Trainings
            </ClickyLink>
          </li>
          <li>
            <ClickyLink className="link-orange underline" href={Routes.MEMBERS}>
              Search Members
            </ClickyLink>
          </li>
          <li>
            <ClickyLink
              className="link-orange underline"
              href={Routes.COMPANIES}
            >
              Search Companies
            </ClickyLink>
          </li>
          <li>
            <ClickyLink
              className="link-orange underline"
              href={Routes.BLOG_VANGST}
            >
              Read the Blog
            </ClickyLink>
          </li>
          <li>
            <ClickyLink className="link-orange underline" href={Routes.REPORTS}>
              Read Cannabis Industry Reports
            </ClickyLink>
          </li>
          <li>
            <ClickyLink className="link-orange underline" href={Routes.ABOUT}>
              About Vangst
            </ClickyLink>
          </li>
        </ListPseudos>
      </div>
    </>
  )
}

CustomError.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}
