import { QueryClient } from '@tanstack/react-query'
import titleize from '@vangst/lib/titleize'
import HttpError from '@vangst/services/HttpError'
import { fetchPage } from '@vangst/services/oogst/page'
import { ElasticSearchEndpoints } from '@vangst/services/oogst/search/useElasticSearch'
import { RobotsEnum } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { useRouter } from 'next/router'
import Breadcrumbs from '../../components/navigation/Breadcrumbs'
import ClickyExplore from '../../components/navigation/ClickyExplore'
import AlertHttpError from '../../domains/application/feedback/AlertHttpError'
import Meta from '../../domains/application/Meta'
import SearchVoltron from '../../domains/search/SearchVoltron'
import { parseParams } from '../../domains/search/useQueryParams'
import { getAddressFromVariables } from '../../domains/search/utils.server'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const queryClient = new QueryClient()
  const page = await fetchPage(queryClient, {
    rawUrl: context.resolvedUrl.split('?')[0],
  })

  if (!page.getPage) {
    return {
      props: {
        notFound: true,
      },
    }
  }

  // This combines the page entity queryString with any search query params added by the user with search filters (duplicated query params are okay)
  const qp = context.resolvedUrl.split('?')[1]
    ? '&' + context.resolvedUrl.split('?')[1]
    : ''
  const pageParams = page?.getPage?.search?.queryString || ''
  const combinedQuery = pageParams + qp
  const variables = { ...parseParams(combinedQuery), first: 24 }
  const address = await getAddressFromVariables(variables)

  // maps page.domainQueryMapping to an ES endpoint
  const domainQueryMapping = {
    'Queries::Search::Credentials': 'searchCredentials',
    'Queries::Search::Candidates': 'searchCandidates',
    'Queries::Search::Clients': 'searchClients',
  }

  const customFilters = !!qp

  const current =
    titleize(page?.getPage?.rawUrl?.split('/')[1].split('-').join(' ') ?? '') ??
    'Search'
  const crumbs = [
    { href: Routes.INDEX, text: 'Home' },
    {
      href: null,
      text: current,
    },
  ]

  return {
    props: {
      address,
      crumbs,
      domainQueryMapping,
      variables,
      combinedQuery,
      customFilters,
      page: page?.getPage ? page.getPage : null,
    },
  }
}

// -------------------------------------

type PropsType = InferGetServerSidePropsType<typeof getServerSideProps>

function SearchBumbleBee(props: PropsType) {
  const {
    address,
    combinedQuery,
    crumbs,
    customFilters,
    notFound,
    page,
    domainQueryMapping,
  } = props
  const { viewer } = useViewer()
  const router = useRouter()

  // re-routes to the normal search experience if the search filters are modified by the user
  // (otherwise they will have a confusing experience because the search filters hidden in the page entity will always be applied)
  if (customFilters) {
    let searchBase
    if (page?.search?.domainQueryName?.includes('Candidates')) {
      searchBase = '/members?'
    } else if (page?.search?.domainQueryName?.includes('Clients')) {
      searchBase = '/companies?'
    } else if (page?.search?.domainQueryName?.includes('Credentials')) {
      searchBase = '/trainings?'
    } else {
      searchBase = '/jobs?'
    }

    router.push(searchBase + combinedQuery)
  }

  const authorizedMemberEndpoint: ElasticSearchEndpoints = viewer?.permissions
    .searchCandidatesAsRecruiter
    ? 'searchCandidatesByRecruiters'
    : viewer?.permissions.searchCandidatesAsClient
    ? 'searchCandidatesByClients'
    : 'searchCandidates'

  const authorizedJobPostingEndpoint: ElasticSearchEndpoints = viewer
    ?.permissions.searchJobPostingsByRecruiter
    ? 'searchJobPostingsByRecruiters'
    : 'searchJobPostings'

  const isMemberSearch = page?.search?.domainQueryName.includes('Candidates')
  const isJobSearch = page?.search?.domainQueryName.includes('JobPostings')
  if (notFound || !page) {
    return <AlertHttpError error={new HttpError(404)} kind="page" />
  }
  const endpoint = isMemberSearch
    ? authorizedMemberEndpoint
    : isJobSearch
    ? authorizedJobPostingEndpoint
    : // @ts-expect-error (complaining about accessing object property with string)
      domainQueryMapping[page?.search?.domainQueryName]
  if (!endpoint) {
    return <AlertHttpError error={new HttpError(404)} kind="page" />
  }
  return (
    <>
      <Meta
        key="meta"
        canonicalUrl={page?.metaInfo.canonicalUrl}
        description={page?.metaInfo.description}
        publishDate={page?.postedAt}
        robots={page?.metaInfo.robots ?? RobotsEnum.NoindexNofollow}
        title={page?.metaInfo.title ?? page?.title}
      />

      <Breadcrumbs crumbs={crumbs} />
      {page?.search != null && (
        <>
          <SearchVoltron
            address={address}
            endpoint={endpoint}
            page={page}
            variables={props.variables}
            showMarkdownContent={true}
          />
        </>
      )}

      <div className="contain-xyc">
        <aside className="mood-white grid content-start gap-4">
          <ClickyExplore href={Routes.JOBS_BROWSE} target="_blank">
            Find Jobs Near You
          </ClickyExplore>
          <ClickyExplore href={Routes.COMPANIES} target="_blank">
            Find Companies Hiring Near You
          </ClickyExplore>
        </aside>
      </div>
    </>
  )
}

export default SearchBumbleBee
