import { IHttpError } from '@vangst/services/HttpError'
import Routes from '@vangst/services/routes'
import { memo } from 'react'
import { MdError } from 'react-icons/md'
import AlertText from '../../../components/feedback/AlertText'
import ClickyLink from '../../../components/navigation/ClickyLink'
import CustomError from '../../../pages/_error'

type PropsType = {
  readonly error: IHttpError
  readonly kind?: 'block' | 'page' | 'status'
}

// TODO: infer from the httpErrors shape?
type ContentType = {
  readonly code: number
  readonly pageMessage: React.ReactNode
  readonly statusMessage: React.ReactNode
  readonly title: string
}

export const httpErrors = {
  a401: {
    code: 401,
    pageMessage: <Http401Message />,
    statusMessage: <SignInOrUp />,
    title: 'Sign In Required.',
  },
  a403: {
    code: 403,
    pageMessage: 'Sorry, that is an unauthorized request.',
    statusMessage: 'Sorry, that is an unauthorized request.',
    title: 'Forbidden.',
  },
  a404: {
    code: 404,
    pageMessage: 'Sorry, that request was not found.',
    statusMessage: 'Sorry, that request was not found.',
    title: 'Not Found.',
  },
  a500: {
    code: 500,
    pageMessage: 'Sorry, something went wrong.',
    statusMessage: 'Sorry, an internal server error occurred.',
    title: 'Internal Server Error.',
  },
  a503: {
    code: 503,
    pageMessage: 'Sorry, something went wrong.',
    statusMessage: 'Sorry, this external service is unavailable.',
    title: 'Service Unavailable.',
  },
  unknown: {
    code: 666,
    pageMessage: 'Sorry, something went wrong.',
    statusMessage: 'Sorry, an unknown client error occurred.',
    title: 'Unknown Client Error.',
  },
} as const

// -------------------------------------

// Use t.httpErrors.a401.pageMessage
function Http401Message() {
  const cn = 'link-orange underline'
  return (
    <>
      <p>
        Please{' '}
        <ClickyLink className={cn} href={Routes.SIGN_IN}>
          sign in
        </ClickyLink>{' '}
        to view this page.
      </p>
      <p>
        Don't have an account?{' '}
        <ClickyLink className={cn} href={Routes.SIGN_UP}>
          Create one here!
        </ClickyLink>
      </p>
    </>
  )
}

function SignInOrUp() {
  const cn = 'link-orange underline'
  return (
    <span>
      Please{' '}
      <ClickyLink className={cn} href={Routes.SIGN_IN}>
        sign in
      </ClickyLink>{' '}
      or{' '}
      <ClickyLink className={cn} href={Routes.SIGN_UP}>
        sign up
      </ClickyLink>{' '}
      to view this resource.
    </span>
  )
}
function Block(props: ContentType) {
  return (
    <p aria-live="assertive" className="flow-y-xs text-sm" role="alert">
      {process.env.NEXT_PUBLIC_OOGST_PRODUCTION == null && (
        <code>{props.code}</code>
      )}
      <span>Sorry, something went wrong.</span>
      <span>
        {props.title}: {props.statusMessage}
      </span>
      <ClickyLink className="link-orange underline" href={Routes.JOBS}>
        Try Searching our Cannabis Jobs instead...
      </ClickyLink>{' '}
    </p>
  )
}

function Page(props: ContentType) {
  return <CustomError statusCode={props.code} />
}

function Status(props: ContentType) {
  return (
    <AlertText aria-live="assertive" className="text-red" icon={MdError}>
      <span>
        {props.statusMessage}
        {process.env.NEXT_PUBLIC_OOGST_PRODUCTION == null && (
          <code className="text-sm text-grey-dark">{` | ${props.code}`}</code>
        )}
      </span>
    </AlertText>
  )
}

function AlertHttpError(props: PropsType) {
  const { error, kind = 'status' } = props
  type keyType = keyof typeof httpErrors
  const key =
    (Object.keys(httpErrors).find(
      (k) => httpErrors[k as keyType]?.code === error.code,
    ) as keyType | undefined) || 'unknown'

  const content = httpErrors[key]
  return kind === 'block' ? (
    <Block {...content} />
  ) : kind === 'page' ? (
    <Page {...content} />
  ) : (
    <Status {...content} />
  )
}

export default memo(AlertHttpError)
